export const cols = [
  {
    title: "Student Id",
  },
  {
    title: "Student Name",
  },
  {
    title: "Phone# EmailID",
  },
  {
    title: "Submitted On",
  },
  {
    title: "Rating ",
  },
  {
    title: "Comment",
  },
];

export const initialValues = {
  created_from: "",
  created_to: "",
};
