import moment from "moment";

export const cols = [
  {
    title: "Name",
  },
  {
    title: "Study Industry",
  },

  {
    title: "Action",
  },
];

export const initialValues = {
  search_key: "",
  industry_id:'',
};

export const initialParams = {
  name: "",
  industry_id:'',
};
