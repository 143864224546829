import country1 from "./assets/country/country1.svg"
import country2 from "./assets/country/country2.svg"
import country3 from "./assets/country/country3.svg"
import country4 from "./assets/country/country4.svg"
import country5 from "./assets/country/country5.svg"
import country6 from "./assets/country/country6.svg"
import country7 from "./assets/country/country7.svg"
import country8 from "./assets/country/country8.svg"
import country9 from "./assets/country/country9.svg"
import country10 from "./assets/country/country10.svg"

export const country = [
  {
    img: country9,
    name: "Australia",
    slug: "australia",
    fullName:"AUSTRALIA"
  },
  {
    img:country6,
    name:"Canada",
    slug:"canada",
    fullName:"CANADA"
  },
  {
    img:country4,
    name: "UK",
    slug: "uk",
    fullName:"UNITED KINGDOM"
  },
  {
    img:country2,
    name: "USA",
    slug: "usa",
    fullName:"UNITED STATES OF AMERICA"
  },
  {
    img:country8,
    name: "Ireland",
    slug: "ireland",
    fullName:"IRELAND"
  },
  {
    img:country10,
    name: "Germany",
    slug: "germany",
    fullName:"GERMANY"
  },
  {
    img:country1,
    name:"Switzerland",
    slug:"switzerland",
    fullName:"SWITZERLAND"
  },
  {
    img:country7,
    name: "Latvia",
    slug: "latvia",
    fullName:"LATVIA"
  },
  {
    img:country5,
    name:"New Zealand",
    slug:"new_zealand",
    fullName:"NEW ZEALAND"
  },
  {
    img: country3,
    name:"UAE",
    slug:"uae",
    fullName:"UAE"
  },
];