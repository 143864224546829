
export const uniLogos = [
  {
    img: require(`./assets/university/1.png`)
  },
  {
    img: require("./assets/university/2.png")
  },
  {
    img: require("./assets/university/3.png")
  },
  {
    img: require("./assets/university/4.png")
  },
  {
    img: require("./assets/university/5.png")
  },
  {
    img: require("./assets/university/6.png")
  },
  {
    img: require("./assets/university/7.png")
  },
  {
    img: require("./assets/university/8.png")
  },
  {
    img: require("./assets/university/9.png")
  },
  {
    img: require("./assets/university/10.png")
  },
  {
    img: require("./assets/university/11.png")
  },
  {
    img: require("./assets/university/12.png")
  },
  {
    img: require("./assets/university/13.png")
  },
  {
    img: require("./assets/university/14.png")
  },
  {
    img: require("./assets/university/15.png")
  },
  {
    img: require("./assets/university/16.png")
  },
  {
    img: require("./assets/university/17.png")
  },
  {
    img: require("./assets/university/18.png")
  },
  {
    img: require("./assets/university/19.png")
  },
  {
    img: require("./assets/university/20.png")
  },
  {
    img: require("./assets/university/21.png")
  },
  {
    img: require("./assets/university/22.png")
  },
  {
    img: require("./assets/university/23.png")
  },
  {
    img: require("./assets/university/24.png")
  },
  {
    img: require("./assets/university/25.png")
  },
  {
    img: require("./assets/university/26.png")
  },
  {
    img: require("./assets/university/27.png")
  },
  {
    img: require("./assets/university/51.png")
  },
  {
    img: require("./assets/university/45.png")
  },
  {
    img: require("./assets/university/30.png")
  },
  {
    img: require(`./assets/university/31.png`)
  },
  {
    img: require("./assets/university/32.png")
  },
  {
    img: require("./assets/university/33.png")
  },
  {
    img: require("./assets/university/34.png")
  },
  {
    img: require("./assets/university/35.png")
  },
  {
    img: require("./assets/university/36.png")
  },
  {
    img: require("./assets/university/37.png")
  },
  {
    img: require("./assets/university/38.png")
  },
  {
    img: require("./assets/university/39.png")
  },
  {
    img: require("./assets/university/40.png")
  },
  {
    img: require("./assets/university/41.png")
  },
  {
    img: require("./assets/university/42.png")
  },
  {
    img: require("./assets/university/43.png")
  },
  {
    img: require("./assets/university/44.png")
  },
  {
    img: require("./assets/university/45.png")
  },
  {
    img: require("./assets/university/46.png")
  },
  {
    img: require("./assets/university/47.png")
  },
  {
    img: require("./assets/university/48.png")
  },
  {
    img: require("./assets/university/49.png")
  },
  {
    img: require("./assets/university/50.png")
  },
  {
    img: require("./assets/university/51.png")
  },
  {
    img: require("./assets/university/52.png")
  },
  {
    img: require("./assets/university/53.png")
  },
  {
    img: require("./assets/university/54.png")
  },
  {
    img: require("./assets/university/55.png")
  },
  {
    img: require("./assets/university/56.png")
  },
  {
    img: require("./assets/university/57.png")
  },
  {
    img: require("./assets/university/58.png")
  },
  {
    img: require("./assets/university/59.png")
  },
  {
    img: require("./assets/university/60.png")
  }
];
